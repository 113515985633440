<template>
	<v-layout justify-center px-5 py-3 row wrap>
		<v-flex v-for="model in quickfolderModels" :key="model.id" shrink ma-2>
			<v-card height="8em" hover :max-width="$vuetify.breakpoint.smAndUp ? '90vw' : '100%'" tile width="20em" @click="openQuickfolderModelForm(model)">
				<v-layout align-center column fill-height justify-space-around pa-2>
					<v-flex class="headline" shrink text-xs-center v-text="model.name" />
				</v-layout>
			</v-card>
		</v-flex>
		<v-flex shrink ma-2>
			<v-card height="8em" hover :max-width="$vuetify.breakpoint.smAndUp ? '90vw' : '100%'" tile width="20em" @click="openQuickfolderModelForm()">
				<v-layout align-center column fill-height justify-space-around pa-2>
					<v-icon color="primary" size="50px">add</v-icon>
					<v-flex v-t="'quickfolders-manager.add_model'" class="headline" text-xs-center shrink />
				</v-layout>
			</v-card>
		</v-flex>
	</v-layout>
</template>

<script>
import QuickFoldersModuleGuard from '@/mixins/ModulesGuards/QuickFolders/QuickFoldersModuleGuard'

export default {
	name: 'QuickfolderModelsList',
	mixins: [QuickFoldersModuleGuard],
	data: function () {
		return {
			quickfolderModels: []
		}
	},
	watch: {
		accountingFirmId: {
			handler: function (val) {
				if (val) {
					this.getQuickfolderModels(val)
				}
			},
			immediate: true
		}
	},
	methods: {
		getModuleEventsActionsMapping: function () {
			return [
				{ event: this.events.QUICKFOLDER_MODEL_CREATED, action: this.addQuickfolderModel },
				{ event: this.events.QUICKFOLDER_MODEL_DELETED, action: this.removeQuickfolderModel },
				{ event: this.events.QUICKFOLDER_MODEL_UPDATED, action: this.updateQuickfolderModel }
			]
		},
		addQuickfolderModel: function (quickfolderModel) {
			this.quickfolderModels.push(quickfolderModel)
		},
		getQuickfolderModels: function (accountingFirmId) {
			return this.service.getQuickfolderModels(accountingFirmId).then(quickfolderModels => {
				this.quickfolderModels = quickfolderModels
			})
		},
		openQuickfolderModelForm: function (quickfolder = {}) {
			this.eventBus.emit(this.events.OPEN_QUICKFOLDER_MODEL_FORM, quickfolder)
		},
		removeQuickfolderModel: function (quickfolderModelId) {
			this.quickfolderModels = this.quickfolderModels.filter(quickfolderModel => quickfolderModel.id !== quickfolderModelId)
		},
		updateQuickfolderModel: function (quickfolderModel) {
			const index = this.quickfolderModels.findIndex(item => item.id === quickfolderModel.id)
			Object.assign(this.quickfolderModels[index], quickfolderModel)
		}
	}
}
</script>
